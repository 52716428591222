import { SelectItem } from "components";
import * as mime from "mime";
import moment, { Moment } from "moment";
import momentTZ from "moment-timezone";
import { createElement, useEffect, useState } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import { AttachmentDTO, NoteDTO } from "../services";
import {intervalToDuration} from "date-fns";

export const reduceNotesToAttachments = (
	notes?: NoteDTO[]
): AttachmentDTO[] => {
	let attachments: AttachmentDTO[] = [];
	if (notes) {
		attachments = notes?.reduce((acc: AttachmentDTO[], cur) => {
			if (cur.attachments) {
				return [...cur.attachments, ...acc];
			}
			return acc;
		}, []);
	}
	return attachments;
};

export function stringToTestId(string: string) {
	return string.replace(/[&/\\#,+()$~%.'":*?<>{}\s]/g, "");
}

export function formatMobileNumber(number: string | undefined) {
	// we used to allow user to enter mobile number without country codes -> default to AUS
	// when this app is deployed to US, this cause issue
	// moving forward we need to make sure all number entered are stored in the E164 format (+61...)
	if (!number) return number;

	const phoneNumber = parsePhoneNumber(number);
	if (!phoneNumber) {
		// default to Australia
		if (number.startsWith("0")) {
			return "+61" + number.substr(1);
		}
		return "+61" + number;
	}
	return number;
}

export function cleanNumber(number: string | undefined) {
	if (!number) return number;

	const phoneNumber = parsePhoneNumber(number);
	// if user enter +610411222333, then they really meant +61411222333
	if (
		phoneNumber &&
		number.startsWith(`+${phoneNumber.countryCallingCode}0`)
	) {
		return (
			`+${phoneNumber.countryCallingCode}` +
			number.substr(phoneNumber.countryCallingCode.length + 2)
		);
	}
	return number;
}

export const isSafari: boolean =
	/Safari/i.test(navigator.userAgent) &&
	/Apple Computer/.test(navigator.vendor);

export const renderComponentXTimes = (
	component: any,
	x: number,
	props?: any,
	children?: any
) => {
	return Array.from({ length: x }, (k, i) =>
		createElement(component, { ...props, key: i }, children)
	);
};

export const isMobileDevice = () => {
	return (
		typeof window.orientation !== "undefined" ||
		navigator.userAgent.indexOf("IEMobile") !== -1
	);
};

export const getMobileOperatingSystem = () => {
	var userAgent = navigator.userAgent || navigator.vendor;

	if (/android/i.test(userAgent)) {
		return "android";
	}

	// iOS detection from: http://stackoverflow.com/a/9039885/177710
	if (/iPad|iPhone|iPod/.test(userAgent)) {
		return "ios";
	}

	return "unknown";
};

export const windowNotTablet = () => {
	const TABLET_THRESHOLD = 700;
	return window.innerWidth < TABLET_THRESHOLD;
};

/**
 * Convert the Date returned from the Back-end without timezone (e.g. '2020-07-15T00:00:00.000' instead of '2020-07-15T00:00:00.000Z')
 * @param date Date constructed from incorrectly parsing date string to local time
 */
export const GetPlatformSpecificDate = (date?: Date): Date => {
	if (!date) {
		return new Date();
	}

	return moment(date)
		.utc(true) // "Mark" the current date as UTC but keeping the date/time value
		.toDate(); // Convert it to local date
};

export const GetDateInTimezone = (date: Moment | Date, timezone?: string) => {
	const tzd = momentTZ.tz(date, `${timezone}`);
	return tzd;
};

export const isEven = (index: number) => {
	return index % 2 === 0;
};

export function makeJSDateObject(date: Date | Moment | null) {
	if (moment.isMoment(date)) {
		return (date as Moment).clone().toDate();
	}

	if (date instanceof Date) {
		return new Date(date.getTime());
	}

	throw new Error("Cannot properly parse argument passed to cloneCrossUtils");
}

export function copy(text: string) {
	return navigator.clipboard.writeText(text);
}

export function loadScript(src: string, position: Element) {
	const script = document.createElement("script");
	script.setAttribute("async", "");
	script.src = src;
	position.appendChild(script);

	return script;
}

export const phoneRegExp =
	/^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

export function getRandomItem<T>(arr: T[]): T {
	return arr[Math.floor(Math.random() * arr.length)];
}

export const EnumToSelect = <T>(
	e: any
): { key: any | null; value: any | null }[] => {
	return Object.keys(e)
		.map((k: any) => ({ key: e[k], value: k }))
		.filter((e) => typeof e.key !== "number");
};

export const GetTimeString = (date?: Date) => {
	let mins = `${date?.getMinutes()}`;
	if (!mins[1]) {
		mins = "0" + mins;
	}
	return `${date?.getHours()}:${mins}`;
};

export const GetTimeDateString = (date?: Date) => {
	return `${GetTimeString(date)} - ${moment(date).format("D/M/YYYY")}`;
};

export const GetDistanceStringFromNow = (date: Date) => {
	const now = new Date();
	return `${moment(date).add(moment().utcOffset(), "minutes").from(now)}`;
};

export const KeyEventIsEnter = (e: any) => e.which === 13 || e.keyCode === 13;

export const PreventSubmitOnEnter = (event: any) => {
	if ((event.charCode || event.keyCode) === 13) {
		event.preventDefault();
	}
};

export const GetUrlExtension = (url: string): string => {
	const parts = url.split(".");
	const ext = parts[parts.length - 1];
	return `${mime.getType(ext)}`;
};

export const GetNoteTitle = (title?: string) => {
	return title ? title : "[UNTITLED]";
};

export const GetFileNameFromUrl = (url: string): string => {
	const parts = url.split("/");
	return parts[parts.length - 1];
};

export const SortAttachmentsByType = (attachments?: AttachmentDTO[]) => {
	if (!attachments) {
		return undefined;
	}

	const images: AttachmentDTO[] = [];
	const audio: AttachmentDTO[] = [];
	const other: AttachmentDTO[] = [];

	attachments.forEach((attachment) => {
		const ext = GetUrlExtension(
			attachment.fileName ? attachment.fileName : "none"
		);
		if (ext.includes("image")) {
			images.push(attachment);
		} else if (ext.includes("audio")) {
			audio.push(attachment);
		} else {
			other.push(attachment);
		}
	});

	return [...images, ...audio, ...other];
};

export const MapMarkingsToSelect = (
	markings: any[],
	def?: string,
	defValue: any = 0
): SelectItem[] => {
	return [
		...(def ? [{ key: `${def}`, value: defValue }] : []),
		...markings
			.sort((m) => m.sort)
			.map((m) => ({ key: `${m.name}`, value: `${m.id}` } as SelectItem)),
	];
};

export const useDebounce = <T>(func: any, args: any, delay: number) => {
	const [value, setValue] = useState<T>();

	useEffect(() => {
		const handler = setTimeout(async () => {
			const result = await func(args);
			setValue(result);
		}, delay);
		return () => {
			clearTimeout(handler);
		};
	}, [func, args, delay]);

	return value;
};

export function acgNameConverter(enumval: string): string {
	switch (enumval) {
		case "Baseline":
			return "General User (Privileged)";
		case "Integrity":
			return "Integrity Units";
		case "CEO_Delegate":
			return "CEO or Delegate";
		case "OrganisationLawyer":
			return "Agency Lawyers";
		case "Admin":
			return "Admin/IT Support";
		case "Intelligence":
			return "Intelligence Units";
		case "Supervisor":
			return "Supervisor";
		case "Restricted":
			return "General User (Restricted)";
		default:
			return enumval;
	}
}

export const parseBadRequestError = (e: any) => {
	if (e?.status === 400 || e?.status === 429) {
		try {
			const error = JSON.parse(e.response);
			return error?.detail;
		} catch (e) {
			return null;
		}
	}
};

export const parseValidationError = (e: any) => {
	if (e?.status === 400) {
		try {
			const error = JSON.parse(e.response);
			return error?.errors;
		} catch (e) {
			return null;
		}
	}
};

export const setCookie = (name, value) => {
	const cookie = name + '=' + (value || '') + '; path=/';
	document.cookie = cookie;
}

export const getCookie = (name) => {
	const nameEQ = name + '=';
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1, c.length);
		}
		if (c.indexOf(nameEQ) === 0) {
			return c.substring(nameEQ.length, c.length);
		}
	}
	return null;
}

export const eraseCookie = (name) => {
	document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
}

export const FormatDuration = (duration: number): string => {
	const dur = intervalToDuration({
		start: 0,
		end: Math.round(duration) * 1000,
	});

	const getString = (s) => {
		if (!s) {
			return "00"
		} else {
			return s < 10 ? `0${s}` : s;
		}
	}

	return dur.hours > 0
			? `${dur.hours}:${getString(dur.minutes)}:${getString(dur.seconds)}`
			: `${dur?.minutes || "00"}:${getString(dur.seconds)}`;
};

export function placeCaretAtEnd(el) {
	el.focus();
	let range = document.createRange();
	range.selectNodeContents(el);
	range.collapse(false);
	let sel = window.getSelection();
	sel.removeAllRanges();
	sel.addRange(range);
}

export const preProcess = (data) => {
	if (!data) {
		return [];
	}

	const json = JSON.parse(data);
	const recognisedPhrases = json.recognizedPhrases;
	if (!recognisedPhrases) {
		return [];
	}

	return recognisedPhrases.map((phrase) => {
		const nBest = phrase.nBest;
		return nBest.map((n) => {
			const offsetSec = nBest[0].words[0].offsetInTicks / 10000000;
			const durationSec = nBest[0].words.reduce((acc, cur) => acc + cur.durationInTicks, 0) / 10000000;
			return {
				display: n.display,
				speaker: phrase.speaker,
				offsetSec,
				durationSec,
				confidence: n.confidence,
				speakerName: phrase.speakerName
			}
		});
	}).flat();
}

export const mapContent = (content: string, data: string, pos: number) => {
	const json = JSON.parse(data);
	const recognisedPhrases = json.recognizedPhrases;
	const updateContent = recognisedPhrases.map((phrase, index) => {
		if (pos === index) {
			return {
				...phrase,
				nBest: phrase.nBest.map((n) => {
					return {
						...n,
						display: content
					}
				})
			}
		} else {
			return phrase;
		}
	})

	return JSON.stringify({
		...json,
		recognizedPhrases: updateContent
	});
}

export const updateSpeakerName = (speakerName: string, data: string, pos: number) => {
	const json = JSON.parse(data);
	const recognisedPhrases = json.recognizedPhrases;
	const recognisesPhrase = recognisedPhrases[pos];
	const updateContent = recognisedPhrases.map((phrase, index) => {
		if (pos === index) {
			return {
				...phrase,
				speakerName: speakerName
			}
		} else {
			return phrase;
		}
	})

	const others: number[] = updateContent
		.filter((phrase, index) =>
			index > pos
			&& phrase.speaker === recognisesPhrase.speaker
			&& !phrase.speakerName)
		.map(x => x.speaker);

	if (!others.length) {
		return JSON.stringify({
			...json,
			recognizedPhrases: updateContent
		});
	}

	if (
		window.confirm(
			`Tag ${others.length} other instances for Speaker ${recognisesPhrase.speaker} as "${speakerName}"`
		)
	) {
		const updatedOthers = updateContent.map((phrase, index) => {
			if (
				index > pos
				&& phrase.speaker === recognisesPhrase.speaker
				&& !phrase.speakerName
			) {
				return {
					...phrase,
					speakerName: speakerName
				}
			}
			return phrase;
		})

		return JSON.stringify({
			...json,
			recognizedPhrases: updatedOthers
		});
	}

	return JSON.stringify({
		...json,
		recognizedPhrases: updateContent
	});
}

export const getSpeakerNames = (data: string) => {
	const json = JSON.parse(data);
	const recognisedPhrases = json.recognizedPhrases;
	const speakerNames: Set<string> = new Set();
	recognisedPhrases.forEach((phrase) => {
		if (phrase.speakerName) {
			speakerNames.add(phrase.speakerName);
		}
	});
	return [...speakerNames];
}

export function stringToHash(string) {
	var hash = 0;
	if (string.length === 0) return hash;

	for (let i = 0; i < string.length; i++) {
		const char = string.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		hash = hash & hash;
	}

	return hash;
}

export * from "./consts";
