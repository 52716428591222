import BaseStore from "./baseStore";
import {TranscriptDto, TranscriptionsClient, TranscriptStatus, UpdateTranscriptionCommand} from "../services";
import {RootStore} from "./index";
import {action, observable} from "mobx";

export class TranscribeStore extends BaseStore {
  private _transcriptionsClient: TranscriptionsClient;
  @observable isOpenTranscriptModal: boolean = false;
  @observable transcriptData: TranscriptDto | null = null;
  @observable attachmentId: string = "";
  @observable blobUrl: string = "";

  constructor(root: RootStore) {
    super(root);
    this._transcriptionsClient = new TranscriptionsClient(this.API_URL);
  }

  @action ToggleTranscriptModal() {
    this.isOpenTranscriptModal = !this.isOpenTranscriptModal;
  }

  @action async getTranscript(guid: string): Promise<TranscriptDto | null> {
    return this.AuthorisedRequest(this._transcriptionsClient, async () => {
      return this._transcriptionsClient
        .getTranscription(guid)
        .then((e) => {
          if (!e) {
            this.error = "Get transcript failed";
            return null;
          }
          this.transcriptData = e;
          this.attachmentId = guid;
          this.isOpenTranscriptModal = true;
          return e;
        })
        .catch((e) => {
          console.log(e)
          this._rootStore.submitExceptionToAppInsight(e);
          this.error = "Get transcript failed";
          return null;
        });
    });
  }

  @action async updateTranscript(command: UpdateTranscriptionCommand): Promise<TranscriptDto | null> {
    return this.AuthorisedRequest(this._transcriptionsClient, async () => {
      return this._transcriptionsClient
        .updateTranscription(command)
        .then((e) => {
          this.transcriptData = e;
          if (command.status === TranscriptStatus.FINALIZED) {
            this._rootStore.noteStore.updateAttachments(e.attachments);
            this.success = "Update transcript success";
          }
          return e;
        })
        .catch((e) => {
          this._rootStore.submitExceptionToAppInsight(e);
          this.error = "Update transcript failed";
          return null;
        });
    });
  }
  
  @action setBlobUrl(blobUrl: string) {
    this.blobUrl = blobUrl;
  }
}
