import { SelectOption } from "../lib/interfaces";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import React from "react";
import {Box, Paper, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '1rem 0 0.25rem 0.75rem'
    },
    cancelBtn: {
      backgroundColor: '#6366F1',
      color: '#FFFFFF',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      cursor: 'pointer',
      padding: '0.75rem 0.25rem',
      borderRadius: '0.375rem',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      outline: 'none',
      marginRight: '0.25rem',
      marginLeft: '1rem',
      transition: 'all 150ms ease-linear',
      whiteSpace: 'nowrap',
      '&:hover': {
        boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
      },
      '&:active': {
        backgroundColor: '#4F46E5',
      },
      '&:focus': {
        outline: 'none',
      },
      '&:disabled': {
        cursor: 'wait',
        backgroundColor: '#E5E7EB',
      },
    },
    textFieldCustom: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#E5E7EB',
        },
        '&:hover fieldset': {
          borderColor: '#CCCCCC',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#4F46E5',
        },
      },
    },
    popperCustom: {
      '& .MuiAutocomplete-option': {
        '&[data-focus="true"]': {
          backgroundColor: '#BBDEFB',
        },
      },
    },
  })
);

const SelectSpeaker: React.FC<{
  onCancel: () => void;
  onChange: (newValue: any) => void;
  speakerNames: string[]
}> = ({ onChange, onCancel, speakerNames }) => {
  const [value, setValue] = React.useState<SelectOption | null>(null);
  const filter = createFilterOptions();
  const classes = useStyles();
  let speakerNameOptions = speakerNames.map(speakerName => ({
    label: speakerName, value: speakerName
  }));

  const handleFilterOptions = (options: any, params: any) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option: any) => inputValue.includes(option.label));

    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        label: `Add "${inputValue}"`,
        value: inputValue,
      });
    }
    return filtered;
  }

  const handleGetOptionLabel = (option: any) => {
    if (option.inputValue) {
      return option.inputValue;
    }

    return option.label;
  }

  const handleRenderOption = (option: any, props: any) => {
    const { key, ...optionProps } = props;
    return (
      <li key={key} {...optionProps}>
        {option.label}
      </li>
    );
  }

  return (
      <div style={{display: 'inline-block'}}>
        <div className={classes.container}>
          <div>
            <Autocomplete
              id={'select-speaker'}
              options={speakerNameOptions}
              value={value}
              onChange={(event, newValue) => onChange(newValue.value)}
              filterOptions={(options, params) => handleFilterOptions(options, params)}
              getOptionLabel={(option) => handleGetOptionLabel(option)}
              renderOption={(option, props) => handleRenderOption(option, props)}
              size={'small'}
              style={{ width: 285 }}
              PaperComponent={(props) => (
                <Paper {...props} className={classes.popperCustom} />
              )}
              renderInput={(params) => (
                <TextField {...params}
                  fullWidth
                  variant={'outlined'}
                  placeholder={'Select...'}
                  className={classes.textFieldCustom}
                />
              )}
            />
          </div>
          <div>
            <button
              onClick={onCancel}
              className={classes.cancelBtn}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
  );
};

export default SelectSpeaker;
